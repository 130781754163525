.App {
  width: 100wh;
  height: 100vh;
}
body{
  font-family: 'Poppins', sans-serif;
}

#components-layout-demo-responsive .logo {
  height: inherit;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}
.ant-layout {
height: inherit !important;
}
.ant-layout-has-sider{
  height: inherit !important;
}